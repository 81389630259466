// import img from "@/assets/headimage/user_icon.png";
import { Checkbox, Image } from "@arco-design/web-react";
import { Fragment, ReactNode, useEffect, useState } from "react";
import moment from "moment";
import HighlightSearch from "@/components/business/HighLightText";
import "./ScriptItem.less";
import UserAvator from "@/pages/CreationSpace/components/UserAvator/UserAvator";
export interface IScriptItemData {
  /**
   * 脚本id
   */
  id: string;
  /**
   * 脚本名称
   */
  name: string;
  /**
   * 脚本描述
   */
  desc: string;
  /**
   * 正文内容
   */
  userGroup?: string[];
  container: string;
  /**
   * 引用统计
   */
  refCount: number;
  /**
   * 跟新时间
   */
  update_at: string;
}

export interface IScriptItem {
  onClick?: () => void;
  data: any;
  /**
   * 是否可以批量操作
   */
  style?: React.CSSProperties;
  isBatchOperable?: boolean;
  isBatchSelected?: boolean;
  actionBarNode: ReactNode;
  useAvatorList?: { name: string; avator: string }[];
  /**
   * checkbox变化触发事件
   */
  handleBatchSelectChange?: (
    e: boolean,
    id: string | number,
    item: IScriptItemData,
  ) => void;
  highTextKeyWord?: string;
}

const ScriptItem: React.FC<IScriptItem> = (props) => {
  const {
    data,
    isBatchSelected,
    isBatchOperable,
    handleBatchSelectChange,
    actionBarNode,
    style,
    onClick,
    highTextKeyWord,
    useAvatorList,
  } = props;

  const [title, setTitle] = useState("");
  const [con, setCon] = useState("");
  const determineContentProcess = (value) => {
    const obj = JSON.parse(value);
    if (obj.section) {
      const { text, title } = parseStructurePublic(obj);
      setTitle(title);
      setCon(text);
      return;
    } else {
      const { text, title } = parseStructureCustom(obj);
      setTitle(title);
      setCon(text);
      return;
    }
  };
  function parseStructurePublic(data) {
    const title = data.section.map((item) => item.subtitle).join(" + ");
    const text = data.section.map((item) => item.text).join(" + ");
    return { title, text };
  }
  function parseStructureCustom(data) {
    const title = (data || [])
      .map((item) =>
        (item?.storyboardName || [])
          .map((sb) => (sb?.content || []).map((c) => c?.text).join(""))
          .join(""),
      )
      .join(" + ");
    const text = (data || [])
      .map(
        (item) =>
          (item?.caption || [])
            .map(
              (caption) =>
                (caption?.content || [])
                  .map(
                    (c) =>
                      (c?.content || [])
                        .map((content) => content?.text || "")
                        .filter(Boolean) // 过滤掉空值
                        .join(""), // 连接文本
                  )
                  .join(""), // 连接每个段落的文本
            )
            .join(""), // 连接每个 caption 的文本
      )
      .join(" , "); // 连接所有 item 的文本，用逗号分隔

    return { title, text };
  }

  useEffect(() => {
    determineContentProcess(data.content);
  }, [data]);

  return (
    <div
      onClick={onClick}
      style={style}
      className={`${isBatchSelected ? "active" : ""} script-item-wrapper ${isBatchOperable ? "" : "disabled-select"}`}>
      <div className="action-bar">{actionBarNode}</div>
      <HighlightSearch searchTerm={highTextKeyWord}>
        <p className="name">
          {isBatchOperable ? (
            <div
              className="checkbox"
              style={{ display: `${isBatchSelected ? "inline-block" : ""}` }}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Checkbox
                checked={isBatchSelected}
                onChange={(e) => {
                  handleBatchSelectChange(e, data.id || "", data);
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {isBatchSelected ? <></> : <span className="name-icon"></span>}
          <span>{data.title}</span>
        </p>
        <p className="title">{title}</p>
        <div className="context">{con}</div>
        <div className="msg-wrapper">
          <div className="user-time">
            <div className="time">
              {useAvatorList && useAvatorList.length > 0 ? (
                <>
                  {(useAvatorList || []).map((item) => {
                    return (
                      <Fragment key={item.name}>
                        {
                          (item?.avator.includes('http') || item?.avator.includes('https')) && <Image width={19} height={19} src={item.avator} style={{ marginRight: '5px', borderRadius: '50%' }}/>
                        }
                        {
                          (!item?.avator.includes('http') && !item?.avator.includes('https')) && <UserAvator
                                name={(item?.name || "").slice(-2)}
                                background={item?.avator || ""}
                                style={{ marginRight: 6 }}
                            />
                        }
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
              更新于
              {moment.unix(data.updated_at).format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
          <div className="count">
            被引用: {data.used_count ? data.used_count : 0}次
          </div>
        </div>
      </HighlightSearch>
    </div>
  );
};

export default ScriptItem;
