import React, { Fragment, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Image, Message, Tooltip } from "@arco-design/web-react";
import {
  drag_row_icon,
  del_row_icon,
  add_row_icon,
} from "@/assets/creationscriptimage";

import cs from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import creationStores from "@/stores/creationScriptStore";
import debounce from "lodash/debounce";

function DragDrop({
  columns,
  insertAndUpdateKeys,
  defaultInitialData,
  sendInfo,
  cursorInfo,
  tablePosition,
}) {
  const [isHovertr, setisHovertr] = useState(-1);
  // 鼠标划过 tr 记录下标
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [downIndex, setDownIndex] = useState(true);
  const [data, setData] = useState<any>();
  const tdRef = useRef([]);
  // 表格是否正在拖拽
  const [isDragging, setIsDragging] = useState(false);
  const isDraggingRef = useRef(true);

  // 引用滚动容器
  const tableContainerRef = useRef(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (source && destination) {
      // 使用数组的解构和重组来更新行的顺序
      const newRows = Array.from(data);
      const [removed] = newRows.splice(source.index, 1);
      newRows.splice(destination.index, 0, removed);
      // 更新状态以反映新的行顺序
      creationStores.compareUpdate(newRows);
      sendInfo();
    }
    setDownIndex(true);
    setHoveredIndex(-1);
    setIsDragging(false);
    setisHovertr(-1);
    isDraggingRef.current = true;
  };

  const onBeforeDragStart = (result) => {
    const { source } = result;
    setDownIndex(false);
    setIsDragging(true);
    isDraggingRef.current = false;
    setHoveredIndex(source?.index);
  };

  const handleScroll = debounce((targetPosition, targetElement) => {
    const tableDom = tableContainerRef.current;
    if (!tableDom) return;
    targetElement.classList.add("blinking-border");
    // 在一定时间后移除动画
    setTimeout(() => {
      targetElement.classList.remove("blinking-border");
    }, 1500);
    tableDom.removeEventListener("scroll", scrollListenerRef.current);
  }, 200);

  const scrollListenerRef = useRef(null);

  const scrollToTarget = (dom) => {
    if (!dom || !tableContainerRef.current) return;
    const tableDom = tableContainerRef.current;
    // 计算目标元素相对于滚动容器的Top值
    let offset = 0;
    let offsetLeft = 0;
    let currentElement = dom;

    while (currentElement && currentElement !== tableDom) {
      offset += currentElement.offsetTop - 15.8;
      offsetLeft += currentElement.offsetLeft;
      currentElement = currentElement.offsetParent;
    }

    tableDom.scrollTo({
      top: offset,
      left: offsetLeft,
      behavior: "smooth",
    });

    // 定义滚动监听器
    const scrollListener = function () {
      handleScroll(offset, dom);
    };

    scrollListenerRef.current = scrollListener;

    tableDom.addEventListener("scroll", scrollListener);
  };

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(toJS(creationStores.dataSource))));
  }, [JSON.stringify(toJS(creationStores.dataSource))]);

  useEffect(() => {
    return () => {
      creationStores.delData([]);
      // 清理滚动监听器
      if (tableContainerRef.current && scrollListenerRef.current) {
        tableContainerRef.current.removeEventListener(
          "scroll",
          scrollListenerRef.current,
        );
      }
    };
  }, []);
  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    let targetDom: HTMLElement | null = null;

    if (
      Object.keys(tablePosition).length &&
      tdRef.current &&
      tdRef.current.length > tablePosition.row &&
      tdRef.current[tablePosition.row]?.[tablePosition.col]
    ) {
      targetDom = tdRef.current[tablePosition.row][
        tablePosition.col
      ] as HTMLElement;

      if (targetDom && tableContainerRef.current) {
        const options: IntersectionObserverInit = {
          root: tableContainerRef.current,
          rootMargin: "0px",
          threshold: 0.1,
        };

        const callback: IntersectionObserverCallback = (
          entries,
          observerInstance,
        ) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              targetDom.classList.add("blinking-border");

              setTimeout(() => {
                targetDom.classList.remove("blinking-border");
              }, 1500);

              observerInstance.unobserve(targetDom);
            } else {
              scrollToTarget(targetDom);
            }
          });
        };

        observer = new IntersectionObserver(callback, options);
        observer.observe(targetDom);
      }
    }

    return () => {
      if (observer && targetDom) {
        observer.unobserve(targetDom);
        observer.disconnect();
      }
    };
  }, [tablePosition, tdRef]);

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onBeforeDragStart={onBeforeDragStart}>
      <Droppable droppableId="tableDroppable">
        {(provided) => (
          <div
            className="creationScript-table-container"
            style={{
              overflowY: "auto",
              maxHeight: "688px",
              position: "relative",
            }}
            ref={tableContainerRef}>
            <table
              className="table"
              style={{ position: "relative" }}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              <thead style={{ position: "sticky", left: 0, top: 0, zIndex: 3 }}>
                <tr>
                  {columns.map((column) => (
                    <th key={column.dataIndex} style={{ width: 999999 }}>
                      <div>
                        <span>{column.title}</span>
                        <span className="th-message-info">
                          {column.messageInfo}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.map((record, rowIndex) => (
                  <Draggable
                    key={record.key}
                    draggableId={`${record.key}`}
                    index={rowIndex}>
                    {(provided) => (
                      <tr
                        className={cs(
                          "drag-position",
                          isDragging && rowIndex === hoveredIndex
                            ? "tr-active"
                            : "",
                          !isDragging && rowIndex === isHovertr
                            ? "tr-hover"
                            : "",
                        )}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}>
                        {columns.map((column, colIndex) => {
                          const val = record[column.dataIndex];
                          return (
                            <td
                              ref={(ref) => {
                                if (!tdRef.current[rowIndex]) {
                                  tdRef.current[rowIndex] = []; // 初始化子数组
                                }
                                tdRef.current[rowIndex][colIndex] = ref; // 存储特定单元格的引用
                              }}
                              key={`${record.key}-${colIndex}`}
                              style={{
                                borderBottom: isDragging
                                  ? "1px solid #cccccc"
                                  : "none",
                                width: 999999,
                              }}>
                              {column.render(
                                val,
                                rowIndex,
                                column.maxLength,
                                colIndex,
                                cursorInfo,
                              )}
                              <div
                                className="drag-handle"
                                style={{
                                  display: colIndex === 0 ? "flex" : "none",
                                }}>
                                <div
                                  onMouseEnter={() => {
                                    setisHovertr(rowIndex);
                                    if (downIndex) {
                                      setHoveredIndex(rowIndex);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (isDraggingRef.current) {
                                      setisHovertr(-1);
                                      if (downIndex) {
                                        setHoveredIndex(-1);
                                      }
                                    }
                                  }}
                                  style={{
                                    opacity: hoveredIndex === rowIndex ? 1 : 0,
                                  }}>
                                  <Tooltip content="添加" position="right">
                                    <Image
                                      src={add_row_icon}
                                      width={20}
                                      preview={false}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (data.length < 15) {
                                          creationStores.compareUpdate(
                                            insertAndUpdateKeys(
                                              data,
                                              "add",
                                              defaultInitialData,
                                              record.key,
                                            ),
                                          );
                                          sendInfo();
                                          Message.success("添加成功");
                                        } else {
                                          Message.warning(
                                            "分镜组个数最多为15个",
                                          );
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip content="删除" position="right">
                                    <Image
                                      style={{ cursor: "pointer" }}
                                      src={del_row_icon}
                                      width={20}
                                      preview={false}
                                      onClick={() => {
                                        creationStores.compareUpdate(
                                          insertAndUpdateKeys(
                                            data,
                                            "delete",
                                            record.key,
                                          ),
                                        );
                                        Message.success("删除成功");
                                        sendInfo();
                                      }}
                                    />
                                  </Tooltip>
                                  <Tooltip content="拖动" position="right">
                                    <Image
                                      src={drag_row_icon}
                                      width={20}
                                      preview={false}
                                      {...provided.dragHandleProps}
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default observer(DragDrop);
