import {
  Form,
  Checkbox,
  Link,
  Button,
  Space,
  Message,
  Input,
} from "@arco-design/web-react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import logoradioa from "@/assets/iconimage/radioa.png";
import logoradiob from "@/assets/iconimage/radiob.png";
import eye_open from "@/assets/iconimage/eye_open.png";
import eye_close from "@/assets/iconimage/eye_close.png";
import "./style/style.less";
const FormItem = Form.Item;
import { observer, inject } from "mobx-react";
import Cookies from "js-cookie";
const Accountlogin = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const formref = useRef(form);
  const [radioopp, setradioopp] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checkdata, setcheckdata] = useState(false);
  const { login } = props;
  const location = useLocation();
  const [urlstate, setstate] = useState(null);
  const [passVisibility, setPassVisibility] = useState<boolean>(false);

  useEffect(() => {
    try {
      const code = location.search.split("=")[1];
      setstate(code);
    } catch (e) {}
  }, [location]);

  function validatePassword(value, callback) {
    if (!value) {
      callback(<div>密码不能为空</div>);
    } else if (!/^.{6,}$/.test(value)) {
      callback(
        <div>请输入6-12个字符，支持数字、大小写字母和符号至少包含一种</div>,
      );
    } else {
      callback();
    }
  }
  useEffect(() => {
    if (formref?.current) {
      if (Cookies.get("Logindata")) {
        const data = JSON.parse(Cookies.get("Logindata"));
        const { phone_number, password } = data;
        formref?.current.setFieldValue("phone_number", phone_number);
        formref?.current.setFieldValue("password", password);
        setcheckdata(true);
      }
    }
  }, []);

  async function onSubmitClick(datalist) {
    if (!radioopp) {
      Message.info("请勾选用户协议");
    } else {
      setLoading(true);
      await login.Accc_use_login(datalist);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (login.isSuccess) {
      if (checkdata) {
        const password = formref.current.getFieldValue("password");
        const phone_number = formref.current.getFieldValue("phone_number");
        const data = {
          phone_number: phone_number,
          password: password,
        };
        localStorage.setItem("Logindata", JSON.stringify(data));
        Cookies.set("Logindata", JSON.stringify(data), {
          expires: 1,
        });
      } else {
        Cookies.remove("Logindata");
      }
      setTimeout(() => {
        if (urlstate) {
          navigate(`/invite?code=${urlstate}`);
        } else {
          navigate("/");
        }
        login.isSuccess = false;
      }, 600);
    }
  }, [login.isSuccess]);

  const actvieclike = () => {
    props.activepassword();
  };

  const validatePhoneNumber = (value, callback) => {
    const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!value || !regex.test(value)) {
      callback(<div>请输入正确的用户名</div>);
    } else {
      callback();
    }
  };

  return (
    <div>
      <Form ref={formref} autoComplete="off" className="form-cottop">
        <FormItem
          field="phone_number"
          rules={[
            { validator: validatePhoneNumber, validateTrigger: "onBlur" },
          ]}>
          <Input
            style={{ fontSize: "16px" }}
            className="form-contorinput"
            autoComplete="username"
            allowClear={true}
            placeholder="请输入账号"
          />
        </FormItem>
        <FormItem
          field="password"
          rules={[
            { validator: validatePassword, validateTrigger: ["onBlur"] },
          ]}>
          <Input.Password
            defaultValue="password"
            autoComplete="current-password"
            placeholder="请输入密码"
            visibility={passVisibility}
            onVisibilityChange={setPassVisibility}
            suffix={
              <img
                src={passVisibility ? eye_open : eye_close}
                style={{ cursor: "pointer" }}
                width={12}
              />
            }
          />
        </FormItem>

        <Space direction="vertical">
          <div id="login-actions">
            {/* <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              记住密码
            </Checkbox> */}
            <Checkbox
              // value={checkdata}
              // defaultValue={checkdata}
              // defaultChecked={checkdata}
              checked={checkdata}
              onChange={() => {
                setcheckdata(!checkdata);
              }}>
              记住密码
            </Checkbox>
            <Link
              onClick={() => {
                actvieclike();
              }}>
              忘记密码
            </Link>
          </div>
          <Button
            type="primary"
            long
            loading={loading}
            onClick={async () => {
              if (formref.current) {
                try {
                  await formref.current.validate();
                  const password = formref.current.getFieldValue("password");
                  const phone_number =
                    formref.current.getFieldValue("phone_number");
                  onSubmitClick({ phone_number, password });
                } catch (_) {
                  // console.error(_);
                }
              }
            }}
            className="styleInputbutton">
            登录
          </Button>
          <div className="radiobody" style={{ cursor: "pointer" }}>
            {radioopp === 0 ? (
              <div
                className="logi_form_titlerekeli"
                onClick={() => {
                  setradioopp(1);
                }}>
                <p className="ardioactiveer"></p>
                <span>我已阅读并同意</span>
              </div>
            ) : (
              <div
                className="logi_form_titlerekeli"
                onClick={() => {
                  setradioopp(0);
                }}>
                <img src={logoradiob} alt="" className="ardioactive" />
                <span>我已阅读并同意</span>
              </div>
            )}

            <span className="login-form-itemhover">
              <span
                style={{
                  color: "#165dff",
                }}
                onClick={() => {
                  window.open("/policy.html", "policy");
                }}>
                平台隐私政策
              </span>
              &nbsp; 与&nbsp;
              <span
                style={{
                  color: "#165dff",
                }}
                onClick={() => {
                  window.open("/agreement.html", "agreement");
                }}>
                平台用户服务协议
              </span>
            </span>
          </div>
          {/* <div>
            <p>17627921976</p>
            <p>@QQQwww123</p>
          </div> */}
        </Space>
      </Form>
    </div>
  );
};
export default inject("login")(observer(Accountlogin));
