import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Message,
  Modal,
  ModalProps,
} from "@arco-design/web-react";
import FormItem from "@arco-design/web-react/es/Form/form-item";
import FormItemTree from "./FormItemTree/FormItemTree";
import { IProjectItem } from "@/stores/spaceStore/projectStore";
import iconImg from "@/assets/create-space/project-modal-icon.png";
import {
  createProject,
  editProjectProject,
} from "@/services/createSpace/project";
import useNavProject from "@/useStore/createspace/useProject";
import { toJS } from "mobx";
import useAllDepartmentAndMember from "@/requestHooks/createSpace/useAllDepartmentAndMember";
import emptyMemberImg from "@/assets/create-space/empty-member.png";
import clearImg from "@/assets/taskcenter/clear_icon.png";
import "./CreateProjectModal.less";

export interface ICreateEngineeringModal extends Omit<ModalProps, "onOk"> {
  onOk: () => void;
  item: IProjectItem;
}

const CreateProjectModal: React.FC<ICreateEngineeringModal> = (props) => {
  const { visible, onCancel, onOk, item, ...rest } = props;
  const uuid: string = JSON.parse(window.localStorage.getItem("UserStore"))
    .userInfo.uuid;
  const { handleRequestProjectList } = useNavProject();
  const { treeData, department, currentMemberIds, currentNickName } =
    useAllDepartmentAndMember(item?.id, uuid);
  const [formRef] = Form.useForm();
  const [chooseLength, setChooseLength] = useState<(string | number)[]>([]);
  console.log("--------------treeData------------------");
  console.log(treeData);
  console.log("--------------treeData------------------");
  const initData = (value: IProjectItem) => {
    if (Object.keys(value).length > 0) {
      return toJS(value);
    }
    return value;
  };

  const init = async () => {
    if (Object.keys(item || {}).length > 0) {
      if (!item || !formRef) return;
      formRef.setFieldsValue({
        ...initData(item),
        memberIds: currentMemberIds,
      });
      setChooseLength(currentMemberIds);
    } else {
      formRef.setFieldValue("owner_name", currentNickName);
    }
  };

  useEffect(() => {
    if (visible) {
      init();
    }
    if (!visible) {
      formRef.resetFields();
    }
  }, [visible, treeData, currentMemberIds, item, currentNickName]);

  const formItenChange = (e: { [key: string]: string | string[] }) => {
    const [key, value] = Object.entries(e)[0];
    if (key === "memberIds") {
      setChooseLength(value as string[]);
    }
  };

  const deleteNotNodeId = (value: (string | number)[]) => {
    if (value && value.length > 0) {
      const departmentIds = department.map((item) => {
        return item.id;
      });
      const clearNodeIdList = [];
      value.forEach((item) => {
        if (![treeData[0]?.key, ...departmentIds].includes(item as string)) {
          clearNodeIdList.push(item);
        }
      });
      return clearNodeIdList;
    }
    return [];
  };

  const validataSubmit = async () => {
    const result = await formRef.validate();
    if (!item?.id) {
      try {
        await createProject({
          name: result.name,
          memberIds: deleteNotNodeId(result.memberIds),
        });
        Message.success(`创建${result.name}项目成功`);
        handleRequestProjectList();
      } catch (e) {
        Message.error(e);
      }
    } else {
      try {
        const navItem = {
          name: result.name as string,
          memberIds: deleteNotNodeId(result.memberIds) as string[],
          id: item.id,
        };
        await editProjectProject(navItem);
        Message.success(`修改${result.name}项目成功`);
        // handleModifyNavList(navItem as IProjectItem);
        handleRequestProjectList();
      } catch (e) {
        Message.error(e);
        console.log(e);
      }
    }
    onOk();
    formRef.resetFields();
  };

  return (
    <Modal
      title={
        <>
          <img
            src={iconImg}
            style={{ width: 27, height: 27, marginRight: 7 }}
            alt=""
          />
          {props.title}
        </>
      }
      style={{ width: 386 }}
      className="vl-modal"
      visible={visible}
      onCancel={() => {
        onCancel();
      }}
      footer={
        <>
          <Button
            onClick={() => {
              onCancel();
              formRef.resetFields();
            }}>
            取消
          </Button>
          <Button
            type="primary"
            onClick={() => {
              validataSubmit();
            }}>
            保存
          </Button>
        </>
      }>
      <Form
        className={"create-project-form-wrapper"}
        autoComplete="off"
        layout={"vertical"}
        form={formRef}
        onChange={formItenChange}>
        <FormItem label="创建人" field="owner_name">
          <Input
            className={"vl-input"}
            placeholder="请填写项目名称"
            maxLength={20}
            showWordLimit
            disabled
          />
        </FormItem>
        <FormItem
          label="项目名称"
          field="name"
          rules={[{ required: true, message: "请填写项目名称" }]}>
          <Input
            className={"vl-input"}
            placeholder="请填写项目名称"
            maxLength={20}
            showWordLimit
          />
        </FormItem>

        <FormItem
          label={
            <div className="project-number-wrapper">
              <div className="project-number-wrapper-title">
                {"项目成员"}
                <span style={{ fontSize: 12, color: "#666666" }}>
                  已选择成员({deleteNotNodeId(chooseLength).length || 0})
                </span>
              </div>
              <img
                src={clearImg}
                alt=""
                style={{ cursor: "pointer", width: "18px" }}
                onClick={() => {
                  formRef.setFieldValue("memberIds", []);
                }}
              />
            </div>
          }
          field="memberIds">
          {treeData && treeData[0]?.children?.length > 0 ? (
            <FormItemTree treeData={treeData} />
          ) : (
            <div className="create-project-empty-member">
              <img src={emptyMemberImg} alt="" />
              <p>暂无部门人员</p>
            </div>
          )}
        </FormItem>
      </Form>
    </Modal>
  );
};
export default CreateProjectModal;
