import React, { useEffect, useState } from "react";
import ContentHeader from "@/components/UI/ContentHeader";
import AnalysisDetails from "./component/AnalysisDetails";
import CreateimportScript from "./component/CreateimportScript";
import { Input, Button, Modal, Message } from "@arco-design/web-react";
import { inject, observer } from "mobx-react";
import { IconSearch } from "@arco-design/web-react/icon";
import Lgparsing from "./Lgparsing";
import vodedoubt from "@/assets/videoparsing/vodedoubt.png";
import vodeodeil from "@/assets/videoparsing/vodeodeil.png";
import headerimg from "@/assets/videoparsing/headerimg.png";
import vodeourl from "@/assets/videoparsing/vodeourl.png";
import "./index.less";
import { Await } from "react-router-dom";
const Videoanalysis = (props: any) => {
  const { videoallyis } = props;
  const [visible, setVisible] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [anaydetil, setanaydetil] = useState(false);

  let isSearching = false;
  const handleSearchChange = async () => {
    if (isSearching) return;
    isSearching = true;
    const url = searchValue.trim();
    const douyinUrlPattern = /^https?:\/\/www\.douyin\.com\/video\/[^\s]+$/;
    if (!url) {
      // Message.warning("输入的视频解析地址不能为空");
    } else {
      if (douyinUrlPattern.test(url)) {
        await videoallyis.script_parse_to({
          url: url,
          source_type: 2,
          name: "",
        });
        setSearchValue("");
      } else {
        Message.warning("视频解析地址必须为抖音详情页地址");
      }
    }
    setTimeout(() => {
      isSearching = false;
    }, 1000);
  };

  const handelClik = () => {
    setanaydetil(false);
    videoallyis.detilsbole = false;
  };
  useEffect(() => {
    if (videoallyis.detilsbole) {
      setanaydetil(true);
    }
  }, [videoallyis.detilsbole]);

  const UrlDeil = () => {
    return (
      <>
        <Modal
          className="lgparsing-modal-body1"
          visible={visible}
          onCancel={() => {
            setVisible(false);
          }}>
          <div className="hover_contel_lapoing">
            <p className="modal-hadertitle">如何获取视频链接？</p>
            <div className="modal-bodycontel">
              <p>1、在抖音PC网页版，找到视频，右键“进入详情页</p>
              <img src={vodeodeil} alt="" />
              <p>2、复制详情页链接</p>
              <img src={vodeourl} alt="" />
              <p style={{ height: "30px" }}></p>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return (
    <div className="videoanalysis">
      {!anaydetil && (
        <div className="video_headerimg">
          <img src={headerimg} alt="" />
        </div>
      )}
      {anaydetil && (
        <ContentHeader
          name="AI视频解析"
          subName={
            <div
              style={
                !anaydetil
                  ? { marginBottom: 36, transition: "margin-bottom 0.5s ease" }
                  : { marginBottom: 0, transition: "margin-bottom 0.8s linear" }
              }>
              你想要的, 在这里可以找到
            </div>
          }
        />
      )}

      {anaydetil && <AnalysisDetails deilClik={handelClik}></AnalysisDetails>}
      {/* {anaydetil && <CreateimportScript></CreateimportScript>} */}
      {!anaydetil && (
        <div className="script-content">
          <div>
            <div className="search">
              <Input
                prefix={<IconSearch style={{ fontSize: "16px" }} />}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e);
                }}
                placeholder="请输入抖音视频链接"
                onPressEnter={() => {
                  handleSearchChange();
                }}
              />
              <Button
                className="search-button"
                onClick={() => {
                  handleSearchChange();
                }}>
                解析视频
              </Button>
            </div>
            <div className="video-content">
              <p className="video-titleimg">
                <img
                  src={vodedoubt}
                  alt=""
                  onClick={() => setVisible(true)}
                  style={{ cursor: "pointer", width: "18px", height: "18px" }}
                />
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setVisible(true)}>
                  获取链接
                </span>
              </p>
              <p>解析成功后将扣除1条视频条数</p>
            </div>
            <div className="contel">
              <Lgparsing></Lgparsing>
            </div>
            <UrlDeil></UrlDeil>
          </div>
          <p className="video-msg">
            *以上内容调用第三方开放大模型生成及豆包AI生成
          </p>
        </div>
      )}
    </div>
  );
};
export default inject("videoallyis")(observer(Videoanalysis));
