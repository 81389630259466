import { Button, Checkbox } from "@arco-design/web-react";
import "./BatchCollection.less";
export interface IBatchCollection {
  handleSelectAll?: () => void;
  handleCancel?: () => void;
  number: number;
  total: number;
  disabeldAll?: boolean;
  disabled?: boolean;
}

const BatchCollection: React.FC<IBatchCollection> = (props) => {
  const {
    handleCancel,
    handleSelectAll,
    number,
    total,
    disabeldAll,
    disabled,
  } = props;
  return (
    <div className="checkbox-wrapper" style={{ height: 30 }}>
      {!disabeldAll ? (
        <Checkbox
          disabled={disabled}
          checked={total === number}
          onChange={(e) => {
            if (e) {
              handleSelectAll();
            } else {
              handleCancel();
            }
          }}>
          全选
        </Checkbox>
      ) : (
        <></>
      )}
      <>
        <span className="tip">已选 {number ? "" : 0}</span>
        {number ? <span className="num-tip">{number}</span> : <></>}

        <span>
          {number > 0 ? (
            <Button
              type="text"
              style={{ marginTop: 0, paddingTop: 0, height: 0 }}
              onClick={handleCancel}
              disabled={disabled}>
              取消
            </Button>
          ) : (
            <></>
          )}
        </span>
      </>
    </div>
  );
};

export default BatchCollection;
