import React, { useRef, useState, useEffect } from "react";
import { Input, Image, Menu, Checkbox, Message } from "@arco-design/web-react";
import { smart_b_icon } from "@/assets/creationscriptimage";
import SmartSearch from "@/components/business/SmartSearch";
import VideoUploader from "@/components/business/VideoUploader";
import MyEditor from "@/components/business/MyEditor";
import "./index.less";
import { toJS } from "mobx";

import User from "@/stores/userStore";
import creationStores from "@/stores/creationScriptStore";

const TextArea = Input.TextArea;
// 选中的文本以及位置

function useData({ dataSendFUn, sendCursor }) {
  // 表格创建的默认数据
  const delay = (callback, time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        callback();
        resolve(1);
      }, time);
    });
  };
  const defaultText = [
    {
      type: "paragraph",
      content: [
        {
          type: "text",
          text: "",
        },
      ],
    },
  ];
  const defaultInitialData = {
    storyboardName: defaultText,
    pictureDescription: defaultText,
    caption: [
      {
        type: "listItem",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
    ],
    materials: [],
    view: defaultText,
    model: defaultText,
    cameraMovement: defaultText,
    place: defaultText,
    prop: defaultText,
    remark: defaultText,
  };

  // AI小助手
  const [suspendAi, setSuspendAi] = useState(false);
  // 智能文案状态
  const [selectStatus, setSelectStatus] = useState<any>("");
  // 输入框传递智能文案内容
  const [helpWrite, setHelpWrite] = useState("");
  // 智能文案输入框显示隐藏的下标
  const [showSmartInputIndex, setShowSmartInputIndex] = useState(-1);
  // 智能文案图标显示隐藏下标
  const [showSmartIndex, setShowSmartIndex] = useState(-1);
  const [showSmartI, setShowSmartI] = useState(-1);
  // 光标定位
  const [location, setlocation] = useState(true);
  const [intelligentValue, setIntelligentValue] = useState<any>();
  const [intelligentUnderway, setIntelligentUnderway] = useState(false);
  const [selectInfo, setSelectInfo] = useState({
    index: -1,
    range: {
      from: -1,
      to: -1,
    },
  });
  const selectInfoRef = useRef(null);
  // 光标信息
  useEffect(() => {
    selectInfoRef.current = selectInfo;
  }, [selectInfo]);
  // 补零
  const setNum = (num) => {
    return num >= 10 ? num : `0${num}`;
  };
  const editorHandler = useRef<any>([]);

  const findText = (objArray) => {
    let result = [];
    objArray.content.forEach((obj) => {
      if (obj.type.name === "text") {
        result.push(obj.text);
      } else if (obj.content) {
        result = result.concat(findText(obj));
      }
    });
    return result.join("");
  };

  // 内容数量计算
  // let count = 0;
  // e.length + count >= maxLength
  // ? e.substring(0, maxLength - count)
  // : e,

  // 修改数组key值
  const insertAndUpdateKeys = (
    arr,
    action,
    newElementNameOrKeyToDelete,
    insertAfterKey = null,
  ) => {
    // 复制原数组，避免直接修改原数组
    const newArr = [...arr];

    if (action === "add") {
      // 找到插入位置
      const insertIndex = newArr.findIndex(
        (element) => element.key === insertAfterKey,
      );
      // 生成新元素的key值
      const newElementKey = newArr.length
        ? newArr.reduce((max, item) => {
            return Math.max(max, item.key);
          }, -Infinity) + 1
        : 1;
      // 插入新元素
      newArr.splice(insertAfterKey == -1 ? newArr.length : insertIndex + 1, 0, {
        key: newElementKey,
        ...newElementNameOrKeyToDelete,
        checked: false,
      });
    } else if (action === "delete") {
      // 找到要删除元素的索引
      const deleteIndex = newArr.findIndex(
        (element) => element.key === newElementNameOrKeyToDelete,
      );
      if (deleteIndex !== -1) {
        // 删除元素
        newArr.splice(deleteIndex, 1);
      }
    }

    return newArr;
  };

  const setCursorFUn = (index, i, pos) => {
    sendCursor({
      RowId: index,
      ColId: i,
      position: pos.from,
      uid: User?.userInfo?.uuid,
      range: pos,
    });
  };
  // 新改版smart所用
  const [smartValue, setSmartValue] = useState("");
  const [smartStatus, setSmartStatus] = useState("");
  const [sendSmartStatus, setSendSmartStatus] = useState("");
  const [itelligentIsExecution, setItelligentIsExecution] = useState(false);

  const useRenderEdit = (
    col,
    index,
    maxLength,
    placeholder,
    type,
    i,
    cursorInfo,
    maxHeight,
  ) => {
    return (
      <MyEditor
        ref={(ref) => {
          editorHandler.current[index] = ref;
          return false;
        }}
        isShowTimer
        editorCurrentStyle={{ maxHeight, overflow: "auto" }}
        cursor={cursorInfo.find((v: any) => v.RowId == index && v.ColId == i)}
        content={
          col
            ? toJS(col)
            : [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "",
                    },
                  ],
                },
              ]
        }
        placeholder={placeholder}
        sendInfo={dataSendFUn}
        type={type}
        limit={maxLength}
        handlefocus={() => {
          if (type == "caption") {
            setShowSmartIndex(index);
            setShowSmartInputIndex(-1);
            if (selectStatus) {
              setlocation(false);
            }
          }
          setShowSmartI(i);
        }}
        handleblur={() => {
          // sendCursor({
          //   RowId: -1,
          //   ColId: -1,
          //   position: 0,
          //   uid: User?.userInfo?.uuid,
          //   range: {
          //     from: -1,
          //     to: -1,
          //   },
          // });
        }}
        selected={({ value, range }) => {
          setSmartValue(
            value ||
              editorHandler.current[index].editor
                .getText()
                .replace(/\n\n\n\n/g, "\n")
                .slice(1),
          );
          setSelectInfo({
            index,
            range,
          });
        }}
        setEditPosition={(ops) => {
          setCursorFUn(index, i, ops);
        }}
        selectInfo={selectInfo}
        handleUpdate={(val) => {
          creationStores.changeCaption(index, type, val);
        }}
        limitPosition={{ bottom: 6, right: 10 }}
      />
    );
  };
  const columns = [
    {
      title: "分镜名称",
      width: 244,
      messageInfo: "",
      dataIndex: "storyboardName",
      maxLength: 20,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            <p className="storyboardName-text">{`#${setNum(index + 1)}`}</p>
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入镜头名称，例如：店家门头",
              "storyboardName",
              i,
              cursorInfo,
              140,
            )}
          </div>
        );
      },
    },
    {
      title: "画面描述",
      width: 244,
      messageInfo: "",
      maxLength: 200,
      dataIndex: "pictureDescription",
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "对于当前镜头画面的描述",
              "pictureDescription",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "台词文案",
      width: 244,
      maxLength: 1000,
      messageInfo: "shift+enter行内折行",
      dataIndex: "caption",
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入此镜头的口播话术",
              "caption",
              i,
              cursorInfo,
              165,
            )}
            <Image
              onMouseUp={() => {
                setShowSmartInputIndex(index);
                delay(() => {
                  setShowSmartIndex(-1);
                }, 100);
              }}
              className="smart-b-icon"
              style={{
                display:
                  showSmartIndex === index && i == showSmartI
                    ? "block"
                    : "none",
              }}
              width={111}
              preview={false}
              src={smart_b_icon}
            />
            <div
              style={{
                display:
                  showSmartInputIndex === index && i == showSmartI
                    ? "block"
                    : "none",
              }}>
              <SmartSearch
                styles={{
                  left: 0,
                }}
                placeholder="帮我写..."
                handelClickBtn={(value: string, type: string) => {
                  setSmartStatus(type);
                  setSendSmartStatus(value);
                  setShowSmartInputIndex(-1);
                  setSuspendAi(false);
                }}
                editValue={smartValue}
                isEnd={itelligentIsExecution}
                handleKeyDown={(event) => {
                  if (event.key === "Escape" || event.keyCode === 27) {
                    setShowSmartInputIndex(-1);
                  }
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "素材",
      width: 244,
      messageInfo: "",
      maxLength: 200,
      dataIndex: "materials",
      render: (col, index) => {
        return (
          <div
            className="td-box"
            style={{ height: 212, overflow: "auto", paddingBottom: 0 }}>
            <VideoUploader index={index} value={col} />
          </div>
        );
      },
    },
    {
      title: "景别",
      width: 244,
      dataIndex: "view",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入景别",
              "view",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "模特",
      width: 244,
      messageInfo: "",
      dataIndex: "model",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入模特",
              "model",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "运镜",
      width: 244,
      messageInfo: "",
      dataIndex: "cameraMovement",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入运镜",
              "cameraMovement",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "场地",
      width: 244,
      dataIndex: "place",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入场地",
              "place",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "道具",
      width: 244,
      messageInfo: "",
      dataIndex: "prop",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入道具",
              "prop",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "备注",
      width: 244,
      messageInfo: "",
      dataIndex: "remark",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入备注",
              "remark",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
  ];
  // 要添加的列
  const [menuData, setMenuData] = useState([
    {
      title: "景别",
      width: 244,
      dataIndex: "view",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入景别",
              "view",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "模特",
      width: 244,
      messageInfo: "",
      dataIndex: "model",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入模特",
              "model",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "运镜",
      width: 244,
      messageInfo: "",
      dataIndex: "cameraMovement",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入运镜",
              "cameraMovement",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "场地",
      width: 244,
      dataIndex: "place",
      messageInfo: "",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入场地",
              "place",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "道具",
      width: 244,
      messageInfo: "",
      dataIndex: "prop",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入道具",
              "prop",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
    {
      title: "备注",
      width: 244,
      messageInfo: "",
      dataIndex: "remark",
      checked: false,
      maxLength: 200,
      render: (col, index, maxLength, i, cursorInfo) => {
        return (
          <div className="td-box">
            {useRenderEdit(
              col,
              index,
              maxLength,
              "输入备注",
              "remark",
              i,
              cursorInfo,
              170,
            )}
          </div>
        );
      },
    },
  ]);

  const dropList = () => {
    return (
      <Menu onClickMenuItem={() => false}>
        {menuData.map((v, i) => (
          <Menu.Item key={`${i}`} style={{ width: 136 }}>
            <Checkbox
              style={{ marginRight: 9 }}
              checked={v.checked}
              onChange={() => {
                menuData[i].checked = !menuData[i].checked;
                setMenuData([...menuData]);
              }}
            />
            {v.title}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const [inMenu, setInMenu] = useState<number>(0);
  useEffect(() => {
    const newArr = JSON.parse(JSON.stringify(toJS(creationStores.dataSource)));
    const dataIndexSet = new Set(menuData.map((item) => item.dataIndex));
    let newInMenu = inMenu;
    setInMenu(++newInMenu);
    const activeDataIndexes = new Set();
    for (const item of newArr) {
      for (const di of dataIndexSet) {
        if (typeof di == "string") {
          if (
            item[di] &&
            item[di].length > 0 &&
            item[di][0]["content"][0]["text"].trim()
          ) {
            activeDataIndexes.add(di);
            // 一旦找到一个有值的 dataIndex，就从 dataIndexSet 中移除，避免重复查找
            dataIndexSet.delete(di);
            // 如果所有的 dataIndex 都已经找到，提前结束循环
            if (dataIndexSet.size === 0) {
              break;
            }
          }
        }
      }
      if (dataIndexSet.size === 0) {
        break;
      }
    }
    if (activeDataIndexes.size > 0 && inMenu > 1) {
      return;
    }
    // 更新 menuData 中的 checked 属性
    const newMenu = menuData.map((v) => ({
      ...v,
      checked: activeDataIndexes.has(v.dataIndex) ? true : v.checked,
    }));
    setMenuData(newMenu);
  }, [creationStores.dataSource]);
  return {
    menuData,
    columns,
    dropList,
    defaultInitialData,
    insertAndUpdateKeys,
    helpWrite,
    selectStatus,
    setSelectStatus,
    suspendAi,
    setSuspendAi,
    location,
    selectInfo,
    editorHandler,
    intelligentValue,
    setIntelligentUnderway,
    smartStatus,
    sendSmartStatus,
    setItelligentIsExecution,
    setSendSmartStatus,
  };
}

export default useData;
