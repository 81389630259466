import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import vodeoiconerror from "@/assets/videoparsing/vodeoiconerror.png";
import vodeoerroer from "@/assets/videoparsing/vodeoerroer.png";
import disable from "@/assets/member/disablemerber.png";
import videoppendelet from "@/assets/videoparsing/videoppendelet.png";
import { inject, observer } from "mobx-react";
import { IconClose, IconDelete } from "@arco-design/web-react/icon";
import {
  Button,
  Popover,
  Progress,
  Spin,
  Tooltip,
} from "@arco-design/web-react";
import { create } from "domain";
import ProgressCircle from "./ProgressCircle";
import Modals from "@/components/UI/Modals";
const analysis = (props) => {
  const { vodiedata, videoallyis } = props;
  let arrinst = null;
  const [errstate, seterrstate] = useState(false);
  const [progress, setProgress] = useState(0);
  const [pset, setpset] = useState(0);
  const [opbole, setopbole] = useState(false);
  const [data, setdata] = useState({
    title: vodiedata.title,
    status: vodiedata.status,
    progress: vodiedata.progress,
    cover:
      "https://img.tukuppt.com/ad_preview/00/14/60/5e5735629c863.jpg!/fw/980",
    err_msg: vodiedata.err_msg,
  });

  const { id } = vodiedata;
  const [Btnclickdelect, setBtnclickdelect] = useState(false);

  const Btnclickdeleclt = () => {
    setBtnclickdelect(!Btnclickdelect);
  };
  const BtnCllckModaldelect = async () => {
    await videoallyis.script_parse_delete_to(id);
    setBtnclickdelect(!Btnclickdelect);
  };

  useEffect(() => {
    setdata(vodiedata);
  }, [vodiedata]);

  let intervalRef = null;
  const iterationRef = useRef<number>(0);
  // useEffect(() => {
  //   if (vodiedata?.progress) {
  //     simulateProgress(Number(vodiedata?.progress) - 1);
  //   }
  // }, [vodiedata]);
  useEffect(() => {
    if (progress == 0) {
      simulateProgress(32);
    } else if (progress == 33) {
      simulateProgress(65);
    } else if (progress == 66) {
      simulateProgress(99);
    }
  }, [progress]);
  const simulateProgress = (nextProgress: number) => {
    if (intervalRef !== null) {
      clearInterval(intervalRef);
    }
    intervalRef = setInterval(() => {
      iterationRef.current += 1;
      if (iterationRef.current < nextProgress) {
        setpset(iterationRef.current);
      } else {
        setpset(nextProgress);
        clearInterval(intervalRef);
        intervalRef = null;
      }
    }, 350);
  };
  useEffect(() => {
    return () => {
      if (intervalRef !== null) {
        clearInterval(intervalRef);
      }
    };
  }, []);

  const Hnadeclcik = (e) => {
    simulateProgress(Number(e));
  };

  const setin = () => {
    const setin = setInterval(() => {
      const fetchProgress = async () => {
        const res = await videoallyis.script_parse_progress_to({ id });
        if (res?.status != 3) {
          clearInterval(setin);
        }
        setdata({
          ...data,
          ...res,
        });
        setProgress(res?.progress);
      };
      fetchProgress();
    }, 2000);
  };

  const navigator = useNavigate();
  useEffect(() => {
    if (data?.status == "3") {
      arrinst = setInterval(() => {
        const fetchProgress = async () => {
          const res = await videoallyis.script_parse_progress_to({ id });
          if (res?.status != 3) {
            clearInterval(arrinst);
          }
          setdata({
            ...data,
            ...res,
          });
          setProgress(res?.progress);

          // simulateProgress(res);
        };
        fetchProgress();
      }, 5000);
    }
    return () => {
      clearInterval(arrinst);
    };
  }, []);

  const calculateValue = (percentage) => {
    if (percentage < 0 || percentage > 100) {
      throw new Error("Percentage must be between 0 and 100.");
    }
    const value = 25 * (percentage / 100);
    const roundedValue = Math.floor(value * 100) / 100;
    return roundedValue;
  };

  function formatTime(milliseconds) {
    if (!milliseconds) return false;
    const minutes = Math.floor(milliseconds / 1000 / 60);
    const seconds = Math.ceil((milliseconds / 1000) % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }
  const handeTime = (updated_at) => {
    const date = new Date(updated_at * 1000);
    console.log(data);

    return date.toLocaleString();
  };

  // 封面照片  素材|| 网路    title  标签  时长
  return (
    <div className="video-agdetil">
      <div
        className="video-agdetil-headerimg"
        onMouseEnter={() => {
          // if (vodiedata.status != 3 && vodiedata.status != 4) {
          setopbole(true);
          // }
        }}
        onMouseLeave={() => {
          // if (vodiedata.status != 3 && vodiedata.status != 4) {
          setopbole(false);
          // }
        }}
        onClick={() => {
          if (data.status != 3 && data.status != 4) {
            // videoallyis.detilsbole = true;
            // videoallyis.detilesid = id;
            navigator(`/analysis-video/${id}`);
          }
        }}>
        <img
          style={{ cursor: "pointer" }}
          className="headerim-contelimg"
          src={
            data?.cover
              ? data?.cover
              : "https://img.tukuppt.com/ad_preview/00/14/60/5e5735629c863.jpg!/fw/980"
          }
          alt=""
        />
        <p className="headerim-contetext">
          {vodiedata.source_type == 1 ? "素材" : "网络"}
        </p>
        {vodiedata?.duration ? (
          <p className="headerim-conTime">{formatTime(vodiedata?.duration)}</p>
        ) : (
          <></>
        )}
        {data?.status != "3" && data?.status != "4" && (
          <div
            className={`footer_modal_title ${opbole ? "slide-in" : "slide-out"}`}>
            <p className="title_text">{data?.title}</p>
            <p className="title_time">
              更新于:{handeTime(vodiedata.updated_at)}
            </p>
          </div>
        )}

        {opbole && (
          <div
            className="jeaderime-context-delete"
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              // videoallyis.script_parse_delete_to(id);
              // Btnclickdelect
              setBtnclickdelect(!Btnclickdelect);
            }}>
            <img src={videoppendelet} alt="" />
          </div>
        )}

        {data?.status == "3" && (
          <div
            className="headerim-contel-Overlay"
            style={{ cursor: "pointer" }}>
            <div className="contel">
              {/* <img src={vodeoUpload} alt="" />
               */}
              <div className="containerto">
                <Progress
                  width={52}
                  type="circle"
                  percent={pset}
                  strokeWidth={5}
                  trailColor="#CCC"
                  color="#FFF"
                />
                {/* <ProgressCircle progress={pset} size={85} /> */}
              </div>
              <p>视频解析中{pset ? pset : 0}%</p>
            </div>
          </div>
        )}
        {data?.status == "4" && (
          <div
            className="headerim-contel-Overlay"
            style={{ cursor: "pointer" }}
            onMouseLeave={() => {
              seterrstate(false);
            }}>
            <div className="contel">
              <img src={vodeoerroer} alt="" />
              <p className="contel-error">
                <span style={{ fontSize: "14px" }}>解析失败</span>
                {errstate ? (
                  // <Popover
                  //   position="top"
                  //   style={{ background: "#000", color: "#fff" }}
                  //   content={
                  //     <span style={{ padding: "4px 10px" }}>
                  //       {data.err_msg}
                  //     </span>
                  //   }>

                  // </Popover>
                  <Tooltip
                    content={
                      <span
                        style={{
                          display: "inline-block",
                          width: "auto",
                          maxWidth: "150px",
                        }}>
                        {data.err_msg}
                      </span>
                    }>
                    <img
                      src={vodeoiconerror}
                      alt=""
                      onMouseMove={() => {
                        seterrstate(true);
                      }}
                    />
                  </Tooltip>
                ) : (
                  <img
                    src={vodeoiconerror}
                    alt=""
                    onMouseMove={() => {
                      seterrstate(true);
                    }}
                  />
                )}

                {/* {errstate && (
                  <span
                    className="error-span"
                    style={{ fontSize: "14px" }}
                    onClick={() => {
                      videoallyis.script_parse_retry_to({
                        id: id,
                      });
                      setin();
                    }}>
                    重试
                  </span>
                )} */}
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <div className="video-agdetil-context">
        <div className="context-titile">
          {data?.status != "3" || data?.title ? (
            data?.title
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {data?.title}
              <p
                style={{
                  textAlign: "center",
                }}>
                标题解析中...
              </p>
            </div>
          )}
        </div>
       
      </div> */}
      <Modals
        width={464}
        height={230}
        title="提示"
        titleIcon={
          <img src={disable} alt="" style={{ width: "26px", height: "26px" }} />
        }
        visible={Btnclickdelect}
        cancelBtn={<Button onClick={Btnclickdeleclt}>取消</Button>}
        subMiteBtn={
          <Button type="primary" onClick={BtnCllckModaldelect}>
            确认
          </Button>
        }
        closeIcon={<IconClose onClick={Btnclickdeleclt} />}>
        <div className="disblae_modals">
          <div className="disblae_modals_body">
            <p className="disblae_modals_titeldis">确认删除?</p>
            <p className="disblae_modals_tit_wrapp">删除后此文件不可恢复</p>
          </div>
        </div>
      </Modals>
    </div>
  );
};
export default inject("videoallyis")(observer(analysis));
