import { useEffect, useState } from "react";
import { Button, Pagination, Upload } from "@arco-design/web-react";
import AnalysiDeil from "./component/analysis";
import KJ from "@/assets/videoparsing/updataKj.png";
import P from "@/assets/videoparsing/updataP.png";
import { inject, observer } from "mobx-react";
import Ton from "@/components/Hocicon";
import Selectmaterials from "@/components/Hocicon";
import { IconShareExternal } from "@arco-design/web-react/icon";
interface IAddres {
  city: string;
  isFinite: boolean;
}

interface IUser {
  name: string;
  age: number;
  address: IAddres;
}

interface IHelloWorld {
  address: string;
  area: number;
  arr: (number | string)[];
  user: IUser;
}
import MaterialFileModal from "@/components/business/MaterialFileModal/MaterialFIleModal";
import allowTypeStore from "@/stores/allowTypeStore";
import { toJS } from "mobx";
import GridContainer from "@/components/UI/GridContainer";
const Lgparsing = (props: any) => {
  const [active, setactive] = useState(false);
  const { videoallyis } = props;
  const [data, setdata] = useState([]);
  const [datatotal, setdatatotal] = useState(0);
  const [uploadVisible, setUploadVisible] = useState(false);

  const [Value, setValue] = useState("material");
  const handleMaterialModalBtnOkClick = (e) => {
    if (e && e[0]) {
      const { media_id, name } = e[0];
      videoallyis.script_parse_to({
        url: media_id,
        source_type: 1,
        name: name,
      });
    }
    setUploadVisible(false);
  };

  // let arrinst = null;
  useEffect(() => {
    setdata(videoallyis.data);
  }, [videoallyis.data]);
  useEffect(() => {
    // setdata(videoallyis.data);
    setdatatotal(videoallyis.datatotal);
  }, [videoallyis.datatotal]);

  useEffect(() => {
    const fetchProgress = async () => {
      await videoallyis.script_parse_record_to({
        page_limit: "23",
        page_index: "1",
      });
    };
    fetchProgress();
  }, []);

  return (
    <div className="lgparsing">
      {uploadVisible ? (
        <MaterialFileModal
          defalutValue={'material'}
          disabledRecordStation={true}
          defaultCollection={[]}
          handleOkBtnClick={handleMaterialModalBtnOkClick}
          onCancel={() => {
            setUploadVisible(false);
          }}
          visible={uploadVisible}
          maxCollectionLength={1}
          allowMetarial={[1, 4]}
          // defalutValue={radioType}
          allowType={toJS(
            allowTypeStore.getAllVideoTypeString(),
          )}></MaterialFileModal>
      ) : (
        <></>
      )}
      <div className="lgparsing-header">
        <p>智能解析</p>
      </div>
      <div className="lgparsing-contel">
        {/* {data &&
          data.map((item, index) => {
            return (
              <div key={item.id} className="lgparsing-contel-analysis">
                <AnalysiDeil vodiedata={item}></AnalysiDeil>
              </div>
            );
          })} */}
        <div style={{ minHeight: '500px' }}>
        <GridContainer dataLength={data.length + 1} boxGap={14}>
          <GridContainer.Item>
            <Ton
              // style={{
              //   marginRight: "24px",
              // }}
              className="lgparsing_contel_updata">
              <Ton.Item className="lgparsing-contel_updatabutton">
                <Button
                  className="updad_but"
                  // icon={<img src={KJ} alt="" />}
                  onClick={() => {
                    setValue("material");
                    setUploadVisible(true);
                  }}>
                  创作空间素材
                </Button>
                <Button
                  className="updad_but"
                  // icon={<img src={P} alt="" />}
                  onClick={() => {
                    setValue("local");
                    setUploadVisible(true);
                  }}>
                  本地上传
                </Button>
              </Ton.Item>
            </Ton>
          </GridContainer.Item>
          {data.map((item, index) => {
            return (
              <GridContainer.Item key={index}>
                <div key={item.id} className="lgparsing-contel-analysis">
                  <AnalysiDeil vodiedata={item}></AnalysiDeil>
                </div>
              </GridContainer.Item>
            );
          })}
        </GridContainer>
        </div>

        <Pagination
          showTotal={(total) => `共 ${total}条`} //是否显示数据总数
          total={datatotal ? datatotal : 1} //                       数据总数
          hideOnSinglePage // 是否在一页的时候 隐藏
          showJumper //	是否显示快速跳转到某页，在 simple 模式下默认为 true	boolean
          // sizeCanChange //	是否可以改变每页条数
          pageSize={23}
          onChange={(current, pageSize) => {
            videoallyis.savadatfiltsize(current);
          }}
          style={{ width: "98%", marginTop: 15, justifyContent: "right" }}
        />

        {/* <MaterialFileModal
          allowType={"..."}
          handleOkBtnClick={(e) => {
            console.log(e);
            setactive(false);
          }}
          onCancel={() => {
            setactive(false);
          }}
          visible={active}></MaterialFileModal> */}
      </div>
    </div>
  );
};
export default inject("videoallyis")(observer(Lgparsing));
