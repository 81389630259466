import React, { useEffect, useState } from "react";
import Footer from "@/components/UI/Footer";
import "@arco-design/web-react/dist/css/arco.css";
import LoginForm from "./form";
import LoginBanner from "./banner";
import "./style/style.less";
import loginimg from "@/assets/Logo/logo.png";
import { badge } from "@/assets/workbenchimage";
function Login() {
  const [count, setCount] = useState(0);
  const [submitTime] = useState("2024-11-28 20:33/test");
  useEffect(() => {
    window.localStorage.clear();
  }, []);
  return (
    <div className="container">
      <div
        className="version"
        onClick={() => {
          setCount(count + 1);
        }}>
        {count > 10 ? submitTime : ""}
      </div>
      <div className="logo">
        <img src={loginimg} className="login_contel_itemIMG" alt="" />
      </div>
      <div className="banner">
        <div className="inner">
          <LoginBanner />
        </div>
      </div>
      <div className="content">
        <div className="inner">
          <div>&emsp;</div>
          <div>&emsp;</div>
          <LoginForm />
          <div className="icp-info">
            <p>Copyright © 2024 合肥摘星人工智能应用软件有限公司</p>
            <p>
              皖ICP备2024062962号-1&emsp;|&emsp;
              <img src={badge} width={13} alt="" />
              &nbsp; 皖公网安备34019202002460号
            </p>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
Login.displayName = "LoginPage";

export default Login;
