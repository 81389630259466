import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Message,
  Tooltip,
} from "@arco-design/web-react";
import { IconLeft } from "@arco-design/web-react/icon";
import Videoany from "./VideoCompont/videoany";
import vodeowarning from "@/assets/videoparsing/vodeowarning.png";
import vodeomusic from "@/assets/videoparsing/vodeomusic.png";
import vodeofile from "@/assets/videoparsing/vodeofile.png";
import vodeotriangle from "@/assets/videoparsing/vodeotriangle.png";
import vodeoconfusion from "@/assets/videoparsing/vodeoconfusion.png";
import combinationaudio from "@/assets/videoparsing/combinationaudio.png";
import vodeomusicdeil from "@/assets/videoparsing/vodeomusicdeil.png";
import nativedialogue from "@/assets/videoparsing/nativedialogue.png";
import cs from "classnames";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import StructuringModal from "@/components/business/StructuringModal";
const AnalysisDetails = (props: any) => {
  const { deilClik } = props;
  const { videoallyis } = props;
  const [scriptmodal, setscriptmodel] = useState(false);
  const [stateTime, setstateTime] = useState(0);
  const [currentTime, setcurrentTime] = useState(0);
  const [statesubtitle, setstatesubtitle] = useState("");
  const [tabactive, settabactive] = useState("");
  const [Scrolling, setScrolling] = useState<string>("");

  const getTrim = (e) => {
    console.log(e);

    if (!isNaN(e)) return e;
    if (isNaN(e)) return 0;
  };
  useEffect(() => {
    console.log(tabactive);
  }, [tabactive]);
  const { id } = useParams();

  const [data, setdata] = useState<any>({
    scenes: [
      {
        title: "门店开头",
        vodiecont:
          "����，听说宁国路海底��新出了99元套��，还有新品上架，今天��定又是人满为����",
        vodeop: "拿起大众点评想到推荐",
        Time: "13s",
        key: "t1",
        state: "0",
        stateTime: "1234",
      },
      {
        title: "门店开头",
        vodiecont:
          "����，听说宁国路海底��新出了99元套��，还有新品上架，今天��定又是人满为����",
        vodeop: "拿起大众点评想到推荐",
        Time: "13s",
        key: "t1",
        state: "0",
        stateTime: "1234",
      },
      {
        title: "门店开头",
        vodiecont:
          "����，听说宁国路海底��新出了99元套��，还有新品上架，今天��定又是人满为����",
        vodeop: "拿起大众点评想到推荐",
        Time: "13s",
        key: "t1",
        state: "0",
        stateTime: "1234",
      },
    ],
  });

  const HandeClickScriptmodal = () => {
    setscriptmodel(!scriptmodal);
  };
  const handeDeilClik = () => {
    deilClik();
  };
  const getMetarialData = (v) => {
    let Stringdata = "";
    if (v) {
      v.description?.map((item: string) => {
        Stringdata += item;
      });
    }
    return Stringdata;
  };
  //  口播文案  样式 && 事件

  const videoOralcopy = (key) => {
    settabactive(key);
    // 点击之后调用 video事件
  };

  //  footer-button dom 弹窗控制 与 事件
  const menuDeildata = [
    {
      title: "原生台词",
      key: "0",
      hande: () => {
        return <img src={nativedialogue} alt="" />;
      },
      handeDeilClik: (e) => {
        console.log(e);
      },
    },
    {
      title: "背景音乐",
      key: "1",
      hande: () => {
        return <img src={vodeomusicdeil} alt="" />;
      },
      handeDeilClik: (e) => {
        console.log(e);
      },
    },
    {
      title: "组合音频",
      key: "2",
      hande: () => {
        return <img src={combinationaudio} alt="" />;
      },
      handeDeilClik: (e) => {
        console.log(e);
      },
    },
  ];
  const [menuClick, setmenuClick] = useState("");
  const dropList = (
    <Menu style={{ width: 117 }}>
      {/* <Menu.Item key="1">Beijing</Menu.Item>
      <Menu.Item key="2">Shanghai</Menu.Item>
      <Menu.Item key="3">Guangzhou</Menu.Item> */}
      <div className="Analysis-Details-menu-flex">
        {menuDeildata &&
          menuDeildata.map((item) => {
            return (
              <div
                className={
                  menuClick == item.key ? "Analysis-Details-menu-flex-item" : ""
                }
                key={item.key}
                onClick={() => {
                  item.handeDeilClik(item.key);
                  setmenuClick(item.key);
                }}>
                {item.hande()}
                <span>{item.title}</span>
              </div>
            );
          })}
      </div>
    </Menu>
  );
  const HandeClickCope = () => {
    if (statesubtitle || statesubtitle != "") {
      navigator.clipboard.writeText(statesubtitle);
      Message.success("复制成功");
    } else {
      Message.info("请选择需要复制的口播文案");
    }
  };
  useEffect(() => {
    const datadetil = async () => {
      const data = await videoallyis.script_parse_rdidget_detilsto(id);
      setdata(data);
      console.log(data);
    };
    datadetil();
    const boxcontel = document.querySelector(".Right-body-top");
    if (!boxcontel) return;
    const tabDom = document.querySelector(".Right-body-contel"); //tab表格
    const copybuttnDom = document.querySelector(".title-button"); // 复制 buuton
    const writingDom = document.querySelector(".Right-body-contel-footel"); //  口播文案
    const removeActive = (e) => {
      if (e.target instanceof Node) {
        if (
          !tabDom.contains(e.target) &&
          !copybuttnDom.contains(e.target) &&
          !writingDom.contains(e.target)
        ) {
          settabactive("");
          setstatesubtitle("")
          setstateTime(0);
          setcurrentTime(0);
        }
      }
    };
    boxcontel.addEventListener("click", removeActive);
    return () => {
      boxcontel.removeEventListener("click", removeActive);
    };
  }, []);

  const number_size = (data) => {
    const v = data / 1048576;
    return v.toFixed(2);
  };

  return (
    <div className="Analysis-Details">
      <div className="Analysis-Details-Left">
        <div className="Left-header-Title">
          <p>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                handeDeilClik();
              }}>
              <IconLeft />
            </span>
            <span>智能解析详情</span>
          </p>
        </div>
        <div className="Left-contel-Video">
          <Videoany
            state={stateTime}
            stateTime={currentTime}
            src={data.url}
            style={{ width: 300, height: 480 }}></Videoany>

          <p className="Left-contel-Video-TitleM">
            基本信息
            {/* {data?.title?.length > 10 ? (
              <Tooltip content={data.title}>
                <span>{data.title}</span>
              </Tooltip>
            ) : (
              <span>{data.title}</span>
            )}

            <span>{number_size(data.size)}.MB</span> */}
          </p>
          <div className="Left-contel-Video-detils">
            <div className="header-contel">
              <div>视频标题:</div> &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="hader_title">
                {data?.title?.length > 20 ? (
                  <Tooltip
                    content={
                      <p className="video_detils_tooltiptitle">{data.title}</p>
                    }>
                    <span>{`${data.title}`}</span>
                  </Tooltip>
                ) : (
                  <span>{`  ${data.title}`}</span>
                )}
              </div>
            </div>

            <p className="footel_title">
              视频空间：&nbsp;&nbsp;
              <span>{number_size(data.size)}.MB</span>
            </p>
          </div>
        </div>

        {/* <div className="Left-footel-Script">
          <div className="script-Property">
            <p>脚本属性</p>
            <div>
              <p>休闲娱乐</p>
              <p>休闲娱乐</p>
              <p>休闲娱乐</p>
            </div>
          </div>
          <div className="script-Property">
            <p>营销策略</p>
            <div>
              <p>休闲娱乐</p>
              <p>休闲娱乐</p>
              <p>休闲娱乐</p>
              <p>休闲娱乐</p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="Analysis-Details-Right">
        <div className="Right-body-top">
          <p className="Right-header-Title">
            <span>脚本结构</span>
            <span className="Warning-icon">
              <Tooltip
                className="Analysis-Details-Title-Tooltip"
                position="top"
                trigger="hover"
                content="点击分镜可定位视频对应画面">
                <img
                  src={vodeowarning}
                  alt=""
                  style={{
                    margin: "0 0 6px 0",
                    cursor: "pointer",
                    width: "12px",
                    height: "12px",
                  }}
                />
              </Tooltip>
            </span>
          </p>

          <div
            onMouseEnter={() => {
              setScrolling("data-body");
            }}
            onMouseLeave={() => {
              setScrolling("");
            }}
            className={cs(
              "Right-body-contel",
              Scrolling == "data-body" ? "" : "hover-body-contel",
            )}>
            <table className="Right-body-table">
              <thead>
                <tr>
                  <th>
                    <p>分镜名称</p>
                  </th>
                  <th>
                    <p>画面描述</p>
                  </th>
                  <th>
                    <p>台词文案</p>
                  </th>
                  <th>
                    <p>时长</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.scenes &&
                  data.scenes.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        onClick={() => {
                          settabactive(`data${index}`);
                          setstateTime(Number(item.start));
                          setcurrentTime(Number(item.duration));
                          setstatesubtitle('');
                        }}
                        className={
                          tabactive == `data${index}`
                            ? index == data.scenes.length - 1
                              ? "tr-active-foter"
                              : "tr-activeto"
                            : ""
                        }>
                        <td className="td-p-title">
                          <div>
                            <p>#0{index + 1}</p>
                            <p>{item.name}</p>
                          </div>
                        </td>
                        <td>
                          <div className="createandeil-voide-datatd">
                            {item.description &&
                              item.description.map((v, i) => {
                                return <span key={i}>{v}</span>;
                              })}
                          </div>
                        </td>
                        <td>
                          {/* <div>{item.vodiecont}</div> */}
                          <div className="createandeil-voide-datatd">
                            {item.subtitle}
                          </div>
                        </td>
                        <td>
                          <div className="createandeil-voide-datatd">
                            {getTrim((item.duration / 1000).toFixed(2)) +
                              `  秒`}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="Right-header-Title-w">
            <div>
              <span>口播文案</span>
              <span className="Warning-icon">
                <Tooltip
                  className="Analysis-Details-Title-Tooltip"
                  position="top"
                  trigger="hover"
                  content="点击分镜可定位视频对应画面">
                  <img
                    src={vodeowarning}
                    alt=""
                    style={{
                      margin: "0 0 6px 0",
                      cursor: "pointer",
                      width: "12px",
                      height: "12px",
                    }}
                  />
                </Tooltip>
              </span>
            </div>
            <Button
              className="title-button"
              onClick={() => {
                HandeClickCope();
              }}>
              复制
            </Button>
          </div>

          <div className="Right-body-contel-footel">
            <div
              onMouseEnter={() => {
                setScrolling("footel-content");
              }}
              onMouseLeave={() => {
                setScrolling("");
              }}
              className={cs(
                "footel-content",
                Scrolling === "footel-content" ? "" : "hover-footel-content",
              )}>
              {data &&
                data.scenes &&
                data.scenes.map((item, index) => {
                  return (
                    <p
                      key={`dp${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        // e.preventDefault();
                        videoOralcopy(`dp${index}`);
                        setstateTime(Number(item.start));
                        setcurrentTime(Number(item.duration));
                        setstatesubtitle(item.subtitle);
                      }}
                      className={tabactive == `dp${index}` ? "pactiver" : ""}>
                      <span className="opcontewrap">{item.subtitle}</span>
                      {tabactive == `dp${index}` && (
                        <span style={{ textAlign: "center" }}>
                          <img
                            src={vodeoconfusion}
                            alt=""
                            style={{ marginRight: "5px" }}
                          />
                          <span>
                            {item.duration && (item.duration / 1000).toFixed(2)}
                          </span>
                        </span>
                      )}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="Right-body-footel">
          {/* <div>
            <Dropdown droplist={dropList} trigger="click" position="top">
              <Button type="outline" className="footer-music">
                <img src={vodeomusic} alt="" />
                提取音频
                <img src={vodeotriangle} alt="" />
              </Button>
            </Dropdown>
          </div> */}
          <StructuringModal
            handleChangeModal={HandeClickScriptmodal}
            isShowModal={scriptmodal}
            title={""}
            scriptData={
              data &&
              data.scenes?.map((v: any) => ({
                storyboardName: v.name,
                pictureDescription: getMetarialData(v),
                caption: v.subtitle,
              }))
            }
          />
          <div className="body-footer-right">
            <Button
              type="text"
              className="footer-button-file"
              onClick={HandeClickScriptmodal}>
              <img src={vodeofile} alt="" />
              <span>使用脚本</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("videoallyis")(observer(AnalysisDetails));
