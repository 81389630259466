import {
  Input,
  Message,
  Popconfirm,
  Popover,
  Tag,
} from "@arco-design/web-react";
import logoIcon from "@/assets/create-space/logo.png";
import Hovericon from "@/assets/videoparsing/selectaciveicon.png";
import defaultImg from "@/assets/create-space/project_nav_default.png";
import avtiveImg from "@/assets/create-space/project_nav_active.png";
import React, { Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { IconDelete, IconMoreVertical } from "@arco-design/web-react/icon";
import ActionBar from "../../components/ActionBar/ActionBar";
import ActionBarItem from "../../components/ActionBar/ActionBarItem";
import EditText, { IforwardRef } from "../../components/EditText/EditText";
import CreateProjectModal from "./CreateProjectModal/CreateProjectModal";
import { observer } from "mobx-react";
import {
  IProjectItem,
  ProjectNavTypeEnum,
} from "@/stores/spaceStore/projectStore";
import editImg from "@/assets/create-space/edit.png";
import deleteImg from "@/assets/create-space/delete.png";
import { editProjectProject } from "@/services/createSpace/project";
import clearImg from "@/assets/create-space/script-clear.png";
import { useNavigate } from "react-router-dom";
import "./ProjectNav.less";
import useNavProject from "@/useStore/createspace/useProject";

const InputSearch = Input.Search;

const toTypeGetNavTag = (type: string | undefined) => {
  const obj: Record<number, ReactNode> = {
    "1": (
      <span
        style={{
          color: "#999999",
          fontSize: 12,
          position: "absolute",
          right: "20px",
        }}>
        默认
      </span>
    ),
    "2": (
      <Tag
        className="nav-tag"
        style={{
          right: "20px",
          borderColor: "#4080FF",
          color: "#4080FF",
          position: "absolute",
        }}>
        共享
      </Tag>
    ),
    "3": <></>,
  };

  return obj[type];
};

interface IProjectNavProps {
  style?: React.CSSProperties;
  projectId?: number;
  projectList: IProjectItem[];
  disabledRecordStation?: boolean;
  handleModifyNavList: (navItem: IProjectItem) => void;
  handleToIdDeleteNavItem: (id: number) => void;
  toKeyWordGetNavItem: (name: string) => IProjectItem[];
  handleProjectIdChange?: (id: number) => void;
  handleProjectItemDelete: (id: number) => void;
}

const ProjectNav: React.FC<IProjectNavProps> = observer((props) => {
  const {
    style,
    projectId,
    projectList,
    handleModifyNavList,
    handleToIdDeleteNavItem,
    toKeyWordGetNavItem,
    handleProjectIdChange,
    handleProjectItemDelete,
    disabledRecordStation,
  } = props;
  const navigate = useNavigate();
  const [createProjectVisible, setCreateProjectVisble] = useState(false);
  const [projectKeyWord, setProjectKeyWord] = useState("");
  const [modelTitle, setModalTitle] = useState<string>("");
  const [editProjectItem, setEditProjectItem] = useState<IProjectItem>(null);
  const { handleRequestProjectList } = useNavProject();
  const refs = useRef<IforwardRef[]>([]);
  const navItemActionConfig = [
    {
      text: "编辑",
      icon: <img src={editImg} alt="" />,
      handleItemClick: async (id: number) => {
        const index = projectList.findIndex((item) => item.id === id);
        setEditProjectItem(projectList[index]);
        setModalTitle("编辑项目");
        setCreateProjectVisble(true);
      },
    },
    {
      text: "删除",
      icon: <img src={deleteImg} alt="" />,
    },
  ];

  const handleAddBtnClick = () => {
    setCreateProjectVisble(true);
  };

  return (
    <div className="nav-wrapper" style={style}>
      <div className="nav-header">
        <div className="title">
          <img src={logoIcon} alt="" className="img-logo" />
          <span className="title">项目列表</span>
        </div>
        <span className="add-icon-wrapper">
          <Popover
            content={
              <div
                style={{
                  color: "white",
                  padding: "8px 6px",
                }}
                className="tip">
                添加项目
              </div>
            }
            trigger={"hover"}
            color={"black"}>
            <img
              src={Hovericon}
              className="add-icon"
              onClick={() => {
                setModalTitle("新建项目");
                handleAddBtnClick();
              }}
            />
          </Popover>
        </span>
      </div>
      <div className="nav-search-wrapper">
        <InputSearch
          value={projectKeyWord}
          allowClear
          placeholder="请输入关键字"
          style={{ width: "100%", marginTop: "20px", height: 38 }}
          onChange={(e) => {
            setProjectKeyWord(e);
          }}
        />
      </div>
      {toKeyWordGetNavItem(projectKeyWord).length === 0 ? (
        <div className="nav-clear">
          <img src={clearImg} alt="" />
          <p>暂无数据</p>
        </div>
      ) : (
        <>
          <ul className="nav">
            {toKeyWordGetNavItem(projectKeyWord).map((item, index) => {
              return (
                <li
                  onClick={() => {
                    if (handleProjectIdChange) {
                      handleProjectIdChange(item.id);
                    }
                  }}
                  key={item.id}
                  className={(projectId as any) === item.id ? "active" : ""}>
                  <div className="nav-item-container">
                    {(projectId as any) === item.id ? (
                      <img src={avtiveImg} alt="" />
                    ) : (
                      <img src={defaultImg} alt="" />
                    )}
                    <span className="name">
                      <EditText
                        inputStyle={{
                          position: "relative",
                          top: "-5px",
                        }}
                        disStopPropagation={true}
                        disabed={true}
                        style={{ width: 90 }}
                        name={item.name}
                        size={"mini"}
                        handleInputEndEnter={async (e) => {
                          const requestData = {
                            name: e,
                            id: item.id,
                          };
                          try {
                            await editProjectProject(requestData);
                            Message.success("修改名称成功!");
                            refs.current[index].toggleIsEdit();
                            handleRequestProjectList();
                          } catch (e) {
                            console.log(e);
                            Message.error(e);
                          }
                        }}
                        ref={(ele) => (refs.current[index] = ele)}
                      />
                    </span>
                  </div>
                  {toTypeGetNavTag(item.type.toString())}
                  {item.type === 1 ? (
                    <></>
                  ) : (
                    <Popover
                      position="rt"
                      content={
                        <ActionBar>
                          {navItemActionConfig.map((step, index) => {
                            return (
                              <Fragment key={index}>
                                {step.text !== "删除" ? (
                                  <ActionBarItem
                                    icon={step.icon}
                                    text={() => {
                                      return step.text;
                                    }}
                                    onClick={() => {
                                      if (step.handleItemClick) {
                                        step.handleItemClick(item.id);
                                      }
                                    }}
                                  />
                                ) : (
                                  <>
                                    <Popconfirm
                                      focusLock
                                      title="确认删除此项目吗?"
                                      content="删除此项目将无法找回，请确认是否删除此项目!"
                                      onOk={async () => {
                                        handleProjectItemDelete(item.id);
                                      }}
                                      onCancel={() => {
                                        console.log("取消");
                                      }}>
                                      <ActionBarItem
                                        icon={step.icon}
                                        text={() => {
                                          return step.text;
                                        }}
                                      />
                                    </Popconfirm>
                                  </>
                                )}
                              </Fragment>
                            );
                          })}
                        </ActionBar>
                      }>
                      <IconMoreVertical
                        className="action-icon"
                        style={{ position: "relative" }}
                      />
                    </Popover>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {createProjectVisible ? (
        <CreateProjectModal
          title={modelTitle}
          visible={createProjectVisible}
          onCancel={() => {
            setCreateProjectVisble(false);
            setEditProjectItem(null);
          }}
          item={editProjectItem}
          onOk={() => {
            setCreateProjectVisble(false);
            setEditProjectItem(null);
          }}
        />
      ) : (
        <></>
      )}
      {!disabledRecordStation ? (
        <div
          className="recyclebin"
          onClick={() => {
            navigate(`/creative-space/recycling-station`);
          }}>
          <IconDelete style={{ marginTop: 2 }} />
          回收站
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});

export default ProjectNav;
