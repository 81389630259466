import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Space, Input, Message } from "@arco-design/web-react";
import "./style/style.less";
import { useLocation, useNavigate } from "react-router-dom";
import logoradioa from "@/assets/iconimage/radioa.png";
import logoradiob from "@/assets/iconimage/radiob.png";
import { observer, inject } from "mobx-react";
import { setTokenToLocalStorage } from "@/utils/token";
import User from "@/stores/userStore";
const FormItem = Form.Item;
const Smslogin = (props: any) => {
  const { login } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [radioopp, setradioopp] = useState(0);
  const formrefSmlong = useRef(form);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [urlstate, setstate] = useState(null);

  useEffect(() => {
    try {
      const code = location.search.split("=")[1];
      setstate(code);
    } catch (e) {}
  }, [location]);
  useEffect(() => {
    if (login.isSuccess) {
      setTimeout(() => {
        // if (urlstate) {
        //   navigate(`/invite?code=${urlstate}`);
        // } else {
        // }
        const searchParams = new URLSearchParams(location.search);
        const next = searchParams.get("next") || "/";
        navigate(next, { replace: true });
        login.isSuccess = false;
      }, 600);
    }
  }, [login.isSuccess]);

  async function onSubmitClick() {
    if (!radioopp) {
      Message.info("请勾选用户协议!");
    } else {
      try {
        const value = await formrefSmlong.current.validate();
        setLoading(true);
        // eslint-disable-next-line react/prop-types
        const result = await login.smslong_login_sms(value);
        // setTokenToLocalStorage();
        setLoading(false);
      } catch (res) {}
    }
  }

  async function validateCaptcha(value, callback) {
    const { getFieldValue } = formrefSmlong.current;
    const phone_number = getFieldValue("phone_number");
    const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!regex.test(phone_number)) {
      return;
    }
    if (!value) {
      callback(<div>验证码不能为空</div>);
    } else {
      await login.verifyCode_to(formrefSmlong).then((res) => {
        if (res == "ok") {
          callback();
        } else if (res == "no") {
          callback(<div>验证码错误</div>);
        }
      });
    }
  }

  const validatePhoneNumber = (value, callback) => {
    const element = document.getElementById("opa") as HTMLButtonElement;
    const elementnode = document.querySelector(
      ".forminputerror",
    ) as HTMLDivElement;
    const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
    if (!value) {
      element.disabled = true;
      element.style.color = "#666666";
      elementnode.style.display = "none";
      callback(<div>手机号不能为空！</div>);
    } else if (!regex.test(value)) {
      callback(<div>手机号格式不正确,请重新输入</div>);
      element.disabled = true;
      element.style.color = "#666666";
      elementnode.style.display = "none";
      element.style.cursor = "default";
    } else {
      element.disabled = false;
      element.style.color = "#165DFF";
      elementnode.style.display = "block";
      element.style.cursor = "pointer";
      callback();
    }
  };

  useEffect(() => {
    const element = document.getElementById("opa") as HTMLButtonElement;
    element.disabled = true;
    element.style.color = "#666666";
    element.style.backgroundColor = "transparent";
    if (element) {
      const handleClick = (element) => {
        login.sendCode_to(formrefSmlong);
        const et = element;
        et.target.disabled = true;
        et.target.style.color = "#666666";
        let count = 60;
        const regex = /^1(3|4|5|6|7|8|9)\d{9}$/;
        const countdownInterval = setInterval(() => {
          et.target.style.cursor = "default";
          et.target.innerHTML = `${count - 1}秒后重发`;
          count--;
          if (count <= 0) {
            const { getFieldValue } = formrefSmlong.current;
            const phone_number = getFieldValue("phone_number");
            if (!regex.test(phone_number)) {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#666666";
              clearInterval(countdownInterval);
              et.target.disabled = true;
              et.target.style.cursor = "default";
            } else {
              et.target.innerHTML = `重新发送`;
              et.target.style.color = "#165DFF ";
              clearInterval(countdownInterval);
              et.target.disabled = false;
              et.target.style.cursor = "pointer";
            }
          }
        }, 1000);
      };
      element.addEventListener("click", handleClick);
      return () => {
        element.removeEventListener("click", handleClick); //销毁
      };
    }
  }, []);
  return (
    <Form className="form-cottopre" ref={formrefSmlong} autoComplete="off">
      <FormItem
        field="phone_number"
        rules={[{ validator: validatePhoneNumber, validateTrigger: "onBlur" }]}>
        <Input
          className="form-contorinput"
          autoComplete="username"
          allowClear={true}
          placeholder="请输入手机号"
        />
      </FormItem>
      <div className="forminputerror">
        短信登录手机账号,未注册用户将自动创建账号
      </div>
      <FormItem
        field="code"
        rules={[{ validator: validateCaptcha, validateTrigger: "onBlur" }]}>
        <Input
          placeholder="请输入验证码"
          autoComplete="current-password"
          suffix={
            <div className="inputyzmbody">
              <div id="inputyzmi"></div>
              <button id="opa">获取验证码</button>
            </div>
          }
        />
      </FormItem>

      <Space direction="vertical" style={{ marginTop: "32px" }}>
        <Button
          type="primary"
          long
          onClick={async () => {
            onSubmitClick();
          }}
          loading={loading}
          className="styleInputbutton">
          登录
        </Button>
        <div className="radiobody" style={{ cursor: "pointer" }}>
          {radioopp === 0 ? (
            <div
              className="logi_form_titlerekeli"
              onClick={() => {
                setradioopp(1);
              }}>
              <p className="ardioactiveer"></p>
              <span>我已阅读并同意</span>
            </div>
          ) : (
            <div
              className="logi_form_titlerekeli"
              onClick={() => {
                setradioopp(0);
              }}>
              <img src={logoradiob} alt="" className="ardioactive" />
              <span>我已阅读并同意</span>
            </div>
          )}
          <span className="login-form-itemhover">
            <span
              style={{
                color: "#165dff",
              }}
              onClick={() => {
                window.open("/policy.html", "policy");
              }}>
              平台隐私政策
            </span>
            &nbsp; 与&nbsp;
            <span
              style={{
                color: "#165dff",
              }}
              onClick={() => {
                window.open("/agreement.html", "agreement");
              }}>
              平台用户服务协议
            </span>
          </span>
        </div>
      </Space>
    </Form>
  );
};
export default inject("login")(observer(Smslogin));
