import { Form, Input, Modal, ModalProps, Select } from "@arco-design/web-react";
import FormItem from "@arco-design/web-react/es/Form/form-item";
import Resolution from "../Resolution/Resolution";
import { useEffect, useState } from "react";
import useNavProject from "@/useStore/createspace/useProject";
export interface ICreateEngineeringModalData {
  id?: string;
  title?: string;
  aspect?: string;
  customizeSize?: [string, string];
  project?: string;
  size?: string;
  updateTime?: string;
}

interface ICreateEngineeringModal extends ModalProps {
  value: ICreateEngineeringModalData;
  submit: (data: any) => void;
}

const formatSendData = (data: any, id: string) => {
  const width = data.size ? Number(data.size.split("*")[0]) : null;
  const height = data.size ? Number(data.size.split("*")[1]) : null;
  const newData = {
    id: id,
    project_id: data.project_id,
    title: data.title,
    width: width,
    height: height,
    aspect: data.aspect,
  };
  if (!width) {
    delete newData.width;
    delete newData.height;
  }
  // return newData;
  if (!id) {
    delete newData.id;
  }
  return newData;
};
export const formatParseData = (data: any) => {
  const newData = {
    title: data.title,
    id: data.id,
    project_id: data.project_id,
    size: data.width ? `${data.width}*${data.height}` : "",
    aspect: data.aspect,
  };
  return newData;
};

const CreateEngineeringModal: React.FC<ICreateEngineeringModal> = (props) => {
  const { visible, value, onCancel, onOk, submit, ...res } = props;
  const { projectList, projectId } = useNavProject();
  const [editId, setEditId] = useState<string>(null);
  const [formRef] = Form.useForm();
  const initFormValue = async (value: ICreateEngineeringModalData) => {
    if (Object.keys(value).length === 0 || !value) {
      await formRef.resetFields();
      formRef.setFieldValue("project_id", projectId);
      return;
    } else {
      setEditId(value.id);
    }

    formRef.setFieldsValue({ ...value });
  };
  useEffect(() => {
    if (formRef) {
      setTimeout(() => {
        initFormValue(value);
      }, 0);
    }
  }, [value, formRef, visible]);

  const formValidate = async () => {
    try {
      const result = await formRef.validate();
      submit(formatSendData(result, editId));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      {...res}
      visible={visible}
      onOk={() => {
        formValidate();
      }}
      onCancel={() => {
        onCancel();
        formRef.resetFields();
      }}
      autoFocus={false}
      focusLock={true}
      unmountOnExit={true}
      className={`${res.className} vl-modal`}>
      <Form
        autoComplete="off"
        layout={"vertical"}
        form={formRef}
        initialValues={{
          aspect: "9:16",
        }}>
        {/* <FormItem label="创建人" field="creator" disabled>
          <Input style={{ width: 270 }} />
        </FormItem> */}
        <FormItem
          label="工程名称"
          field="title"
          rules={[{ required: true, message: "工程名称是必填项" }]}>
          <Input placeholder="请填写工程名称" />
        </FormItem>
        <FormItem
          label="画面比例"
          field="aspect"
          rules={[{ required: true, message: "画面比例是必选项" }]}>
          <Resolution />
        </FormItem>
        <FormItem
          rules={[{ required: true, message: "请选择项目" }]}
          label="保存位置"
          field={"project_id"}
          tooltip={<div>请选择保存工程位置</div>}>
          <Select
            options={projectList.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })}></Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default CreateEngineeringModal;
