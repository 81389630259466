export const usecChangeCreationStore = (lastArr) => {
  // ...JSON.parse(JSON.stringify(firsetArr)),
  const nArr = JSON.parse(JSON.stringify(lastArr));
  return nArr.map((item, index) => {
    return {
      key: index++,
      storyboardName: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: item.storyboardName,
            },
          ],
        },
      ],
      pictureDescription: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: item.pictureDescription,
            },
          ],
        },
      ],
      caption: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: item.caption,
                },
              ],
            },
          ],
        },
      ],
      materials: item.materials,
      // view: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.view,
      //       },
      //     ],
      //   },
      // ],
      // model: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.model,
      //       },
      //     ],
      //   },
      // ],
      // cameraMovement: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.cameraMovement,
      //       },
      //     ],
      //   },
      // ],
      // place: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.place,
      //       },
      //     ],
      //   },
      // ],
      // prop: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.prop,
      //       },
      //     ],
      //   },
      // ],
      // remark: [
      //   {
      //     type: "paragraph",
      //     content: [
      //       {
      //         type: "text",
      //         text: item.remark,
      //       },
      //     ],
      //   },
      // ],
    };
  });
};
